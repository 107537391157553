.page-header {
  background: #FFF;
  margin-bottom: 3rem;

  h1 {
    padding: 1rem 0;
    background: #FFF;
  }
  img {
    max-width: 100%;
    max-height: 90px;
    width: auto;
  }
}

.main-headline {
  font-weight: 600;
  font-size: 24px;

  h2 {
    margin-bottom: 2rem;
  }

  h3 {
    margin-bottom: 1.5em;
  }
}
