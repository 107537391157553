.form-group {
  margin-bottom: 1rem;
}

.form-check {
  @extend .flow-btn;
  padding: 0;

  .fa {
    display: none;
  }

  &.selected {
    background: $mydegree-blue;
    color: $white;
    border-color: $mydegree-blue;

    .fa {
      display: inline-block;
      font-weight: 400;
    }
  }

  label {
    width: 100%;
  }
}

input{
  &.form-control {
    &:focus {
      box-shadow: none;
    }
  }
  &.form-check-input {
    &:checked {

    }
  }
}
